<template>
  <div id="parent-component" class="page-content">
    <!-- LOADING BOX -->
    <default-loading v-if="collection_loading" :minHeight="'80vh'"></default-loading>
    <!-- CONTENT -->

    <div v-show="!collection_loading && Object.keys(item).length === 0"
      class="course-details-wrapper topic-1 uk-light pt-5">
      <div class="container p-sm-0">
        <div uk-grid>
          <div class="course-details">
            <h1 class="text-primary">
              {{ $t('collectionDetail.access_denied') }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div id="open_to_add_content_modal" style="z-index: 9999999;" uk-modal class="uk-flex-center">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <CollectionContentModal ref="addContentModal" @onSubmit="addContentModalSubmit" :collection-id="collectionId">
        </CollectionContentModal>
      </div>
    </div>


    <div v-show="!collection_loading && Object.keys(item).length" class="course-details-wrapper topic-1 uk-light pt-5">
      <div class="container p-sm-0">
        <div uk-grid>
          <div class="uk-width-2-3@m" style="margin-left: 27rem">
            <div class="course-details">
              <h1>
                {{ item.name }}
              </h1>
              <div class="course-details-info mt-4">
                <ul>
                  <li>
                    <i class="icon-feather-flag"></i>
                    {{ $t("collectionDetail.start_date") }}:
                    {{ fixDate(item.created_at) }}
                  </li>
                  <li v-if="typeof item.user !== 'undefined'">
                    <i class="icon-feather-user"></i>
                    {{ $t("collectionDetail.who_created") }}:
                    <router-link :to="{ name: 'user.show' }">
                      {{ item.user.name + ' ' + item.user.surname }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <nav class="responsive-tab style-5">
              <ul
                uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                <li>
                  <a href="javascript:void(0)"><i class="uil-list-ul"></i>{{ $t("collectionDetail.contents") }}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div v-show="!collection_loading && Object.keys(item).length" class="container">
      <div class="uk-grid-large mt-4" uk-grid>
        <div class="uk-width-2-3@m">
          <div v-if="successMessage != null" class="uk-alert-success position-relative" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p class="m-0" style="color: green">
              <i aria-hidden="true" class="fa fa-check d-inline-flex mr-2"></i>{{ successMessage }}
            </p>
          </div>
          <div v-if="errorMessage != null" class="uk-alert-danger bg-soft-danger position_relative" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p class="m-0" style="color: red">{{ errorMessage }}</p>
          </div>

          <ul v-if="showShareArea" id="course-intro-tab" class="uk-switcher mt-4 mb-4">
            <li>
              <div class="uk-alert-primary2 p-4" uk-alert>
                <a class="uk-alert-close" uk-close @click="showShareArea = false"></a>
                <h4>
                  <i class="uil-share-alt"></i>
                  {{ $t("collectionDetail.share_collection") }}
                </h4>
                <p> {{ $t("collectionDetail.share_message_content") }} </p>
                <div class="pt-4 pb-3 ">

                  <template v-for="user in users">
                    <span v-if="sharedIds.includes(user.id)" class="uk-label p-2 mx-1"
                      style="text-transform: capitalize;border-radius:10px;background-color:#fff;color:#3e416d;">
                      <i class="uil-user"></i> {{ user.name + ' ' + user.surname }}
                    </span>
                  </template>
                </div>
                <button class="btn btn-success btn-icon-label mb-4" href="#modal-overflow" type="button" uk-toggle>
                  <span class="btn-inner--icon">
                    <i class="uil-user-plus"></i>
                  </span>
                  <span class="btn-inner--text mb-3">{{ $t("collectionDetail.person_add_remove") }} </span>
                </button>
                <textarea v-model="sharedMessage" cols="30" :placeholder="$t('collectionDetail.write_your_message_here')"
                  rows="2"></textarea>
                <button class="btn btn-secondary mt-2 mr-1" type="button" @click="saveSharedIds">
                  {{ $t("collectionDetail.send") }}
                </button>
                <button class="btn btn-soft-secondary mt-2" type="button" @click="showShareArea = false">
                  {{ $t("collectionDetail.cancel") }}
                </button>
                <button class="btn btn-secondary mt-2" href="#modal-overflow" style="float: right" type="button"
                  uk-toggle>
                  <i class="uil-history"></i> {{ $t("collectionDetail.share_history") }}
                </button>
              </div>

              <div id="modal-overflow" uk-modal container="#parent-component">
                <div class="uk-modal-dialog">
                  <button class="uk-modal-close-default" type="button" uk-close></button>
                  <div class="uk-modal-header">
                    <h3 class="uk-modal-title">{{ $t("collectionDetail.person_add_remove") }}</h3>
                  </div>

                  <div class="uk-modal-body" uk-overflow-auto>
                    <div class="row">
                      <input type="text" class="form-control" v-model="share_user_search" @input="getSearchUser"
                        :placeholder="$t('general.search')">
                    </div>

                    <template v-for="(user, index) in searchUserList">
                      <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                        <div class="uk-width-auto position-relative">
                          <img :alt="user.name + ' ' + user.surname" :src="user.photo_link"
                            class="rounded-circle avatar-sm" />
                        </div>
                        <div class="uk-width-expand">
                          <p class="uk-text-truncate mb-0">{{ user.name + ' ' + user.surname }}</p>
                        </div>

                        <div v-if="sharedIds.includes(user.id)">
                          <p>
                            <b><i class="uil-check"></i> {{ $t("collectionDetail.person_added") }} </b>
                          </p>
                          <button @click="removeSharedId(user.id)" class="btn btn-secondary btn-sm"><i
                              class="uil-times"></i>
                            {{ $t("collectionDetail.cancel") }}
                          </button>
                        </div>
                        <div v-else>
                          <a class="btn btn-primary" href="javascript:void(0);" @click="sharedIds.push(user.id)"><i
                              class="uil-user-plus"></i> {{ $t("collectionDetail.add") }}</a>
                        </div>
                      </div>
                      <hr />
                    </template>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul v-if="userInfo.id === item.user_id" id="course-detail-tab" class="mt-4">
            <li>
              <div class="uk-alert-primary2 p-4" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                <h4>
                  <i class="uil-table"></i>
                  {{ $t('collectionDetail.collection_details') }}
                </h4>
                <div class="mb-2">
                  <label>{{ $t('collectionDetail.name') }}</label>
                  <input v-model="item.name" class="uk-input border-0 bg-white text-primary" type="text" />
                </div>
                <div class="mb-2">
                  <label>{{ $t('collectionDetail.description') }}</label>
                  <textarea v-model="item.description" class="uk-textarea border-0 bg-white text-primary">
                      </textarea>
                </div>
                <div class="mb-2">
                  <label>{{ $t('collectionDetail.tags') }}</label>
                  <multiselect v-model="hashtags" :multiple="true" :openDirection="'bottom'" :options="hashtags_options"
                    :taggable="true" deselectGroupLabel="Grubun seçimini kaldırmak için enter'a basın"
                    deselectLabel="Kaldırmak için enter'a basın" label="name" :placeholder="$t('general.add_tag')"
                    selectGroupLabel="Grup seçmek için enter'a basın" selectLabel="Seçmek için enter'a basın"
                    selectedLabel="Seçilmiş" :tag-placeholder="$t('general.add_tag')" track-by="id"
                    @change="(val) => { hashtags = val }" @close="isClosedHashtagSelector = true"
                    @open="isClosedHashtagSelector = false" @tag="addTag" />
                </div>

                <div class="d-flex justify-content-between align-items-center mt-4">
                  <div class="d-flex justify-content-start align-items-center">
                    <span class="mr-2">{{ $t('collectionDetail.is_public') }}</span>
                    <label class="uk-switch mb-0" for="is_public_check">
                      <input id="is_public_check" v-model="item.is_public" type="checkbox" @change="changePublic">
                      <div class="uk-switch-slider"></div>
                    </label>
                  </div>

                  <button class="btn btn-success" @click="saveDetail">
                    <i class="uil-check-circle" style="font-size: 120%;"></i> {{ $t('general.save') }}
                  </button>
                </div>

              </div>
            </li>
          </ul>

          <ul class="course-curriculum-list" style="background: #fff">
            <li v-for="contents in item.contents" :class="{
              read: contents.contentable_id == 1,
              watch: contents.contentable_id == 2,
              quiz: contents.contentable_id == 3,
              interactive: contents.contentable_id == 4,
            }">{{
}}
              <template
                v-if="contents.pivot && checkModelAble(contents.pivot.contentable_type) != 'CollectionExternalContent' && contents.pivot.contentable_type != null">
                <router-link :to="{
                  name: getModelRouteName(contents.pivot.contentable_type),
                  params: { id: contents.pivot.contentable_id }
                }">
                  {{ contents.title }}
                </router-link>
              </template>
              <template v-else>
                <a href="#" @click="downloadURI(contents.url || contents.file_link)">
                  {{ contents.title }}
                </a>
              </template>
              <span v-if="item.user_id === userInfo.id">
                <button class="btn btn-animated btn-animated-y" type="button" @click="removeContent(contents.pivot.id)">
                  <span class="btn-inner--visible">
                    <i class="uil-trash"></i> </span><span class="btn-inner--hidden">{{ $t('general.remove') }}</span>
                </button>
              </span>
            </li>
          </ul>
        </div>

        <div class="uk-width-1-3@m uk-flex-first">
          <div class="course-card-trailer" uk-sticky="top: 10 ;offset:95 ; media: @m ; bottom:true">
            <div class="course-thumbnail">
              <img id="collection-image-holder" :src="item.cover_image_link" alt="" />
              <input v-if="userInfo.id === item.user_id" ref="file" style="display: none" accept="image/*" type="file"
                @change="onChangeFile" />
              <!-- <a class="play-button-trigger show" href="#trailer-modal" uk-toggle=""> </a> -->
              <button v-if="userInfo.id === item.user_id" class="btn btn-primary btn-icon-label" style="
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -20px;
                    margin-left: -105px;
                  " type="button" @click="$refs.file.click()">
                <span class="btn-inner--icon">
                  <i class="uil-image"></i>
                </span>
                <span class="btn-inner--text">{{ $t("collectionDetail.update_image") }}</span>
              </button>
            </div>

            <div v-if="userInfo.id === item.user_id" class="p-3">
              <!--              <p style="font-size: 1rem">%0 {{ $t("collectionDetail.completed") }}</p>-->
              <!--              <div class="course-progressbar mb-4">-->
              <!--                <div class="course-progressbar-filler continue" style="width: 0%" uk-tooltip="title: %0 Tamamlandı; pos: top-center"></div>-->
              <!--              </div>-->
              <div class="uk-child-width-2-2 uk-grid-small mb-4" uk-grid>
                <div>
                  <a class="uk-width-1-1 btn btn-danger transition-3d-hover" href="javascript:void(0)"
                    @click="checkModelControl(item.contents[0]) ? goPage(item.contents[0].pivot.contentable_id, item.contents[0].pivot.contentable_type) : downloadURI(item.contents[0].url || item.contents[0].file_link)">
                    <i class="uil-play"></i> {{ $t("collectionDetail.start") }}
                  </a>
                </div>

                <div>
                  <a class="uk-width-1-1 btn btn-danger transition-3d-hover" href="javascript:void(0)"
                    @click="showAddContentModal">
                    <i class="uil-plus"></i> {{ $t("collectionDetail.add_content") }}
                  </a>
                </div>
                <div v-if="item.is_public">
                  <a class="uk-width-1-1 btn btn-primary transition-3d-hover" href="javascript:void(0)"
                    @click="showShareArea = !showShareArea">
                    <i class="uil-share-alt"></i>
                    {{ $t("collectionDetail.share_collection") }}
                  </a>
                </div>
                <div>
                  <a class="uk-width-1-1 btn btn-secondary transition-3d-hover" @click="handleCollectionToDelete">
                    <i class="uil-trash-alt"></i>
                    {{ $t("collectionDetail.delete") }}
                  </a>
                </div>
              </div>
            </div>
            <div v-else class="p-3">
              <div class="uk-child-width-2-2 uk-grid-small mb-4" uk-grid>
                <div v-if="itemIsFollowing">
                  <a class="uk-width-1-1 btn btn-warning transition-3d-hover" href="javascript:void(0)"
                    @click="collectionFollowUnfollow(0)">
                    <i class="uil-minus-circle"></i> {{ $t("collectionDetail.unfollow") }}
                  </a>
                </div>
                <div v-else>
                  <a class="uk-width-1-1 btn btn-danger transition-3d-hover" href="javascript:void(0)"
                    @click="collectionFollowUnfollow(1)">
                    <i class="uil-plus-circle"></i> {{ $t("collectionDetail.follow") }}
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import Multiselect from "vue-multiselect";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import CollectionContentModal from "@/view/User/CollectionContentModal";
import {
  CREATE_ITEM,
  DELETE_ITEM_BY_ID,
  ERROR as REST_ERROR,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  LOADING as REST_LOADING,
  UPDATE_ITEM_BY_ID
} from "@/core/services/store/REST.module";

export default {
  name: "CollectionDetails",
  components: { DefaultLoading, Multiselect, CollectionContentModal },
  data() {
    return {
      hashtags: [],
      isClosedHashtagSelector: false,
      hashtags_options: [],
      share_user_search: "",
      CollectionDetailUrl: "api/collection/",
      CollectionRemoveContentUrl: "api/collection/content/",
      userListUrl: "api/collection-users/",
      saveIdsUrl: "api/collection-shared/update/",
      item: [],
      errorMessage: null,
      successMessage: null,
      collectionImage: null,
      showShareArea: false,
      users: [],
      searchUserList: [],
      sharedIds: [],
      sharedMessage: null,
      isFollowing: false,
      collectionId: null,
    };
  },
  computed: {
    itemIsFollowing() {
      return this.isFollowing;
    },
    userInfo: {
      get: function () {
        return this.authUser
      },
      set: function (newValue) {
      },
    },
    collection_loading: {
      get() {
        return this.$store.getters[REST_LOADING];
      },
      set(value) {
      },
    },
    restError: {
      get() {
        return this.$store.getters[REST_ERROR];
      },
      set(value) {
      },
    },
  },
  methods: {
    getSearchUser() {
      this.searchUserList = this.users.filter(user => {
        const fullName = `${user.name} ${user.surname}`.toLowerCase();
        const query = this.share_user_search.toLowerCase();
        return fullName.includes(query);
      });
    },
    downloadURI(uri) {
      window.open(uri);
      this.successMessage = this.$t("collectionDetail.content_opened");
      return false;
    },
    showAddContentModal() {
      UIkit.modal("#open_to_add_content_modal").show();
    },
    addContentModalSubmit() {
      setTimeout(() => {
        UIkit.modal("#open_to_add_content_modal").hide();
      }, 1000);
      this.getResultItems();
    },
    changePublic() {
      let is_public = this.item.is_public;
      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: this.CollectionDetailUrl + "change-public/" + this.collectionId,
        contents: { is_public: is_public ? 1 : 0 },
        acceptPromise: true
      })
    },
    goPage(id, name) {
      this.$router.push({ name: this.getModelRouteName(name), params: { id: id } });
    },
    collectionFollowUnfollow(follow) {
      let self = this;
      let form = new FormData();

      form.append("follow", follow);
      form.append("_method", 'PUT');

      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: this.CollectionDetailUrl + "follow-unfollow/" + this.collectionId,
        contents: form,
        acceptPromise: true
      })
        .then((result) => {
          if (result.status) {
            if (typeof result.data.follow !== "undefined") {
              self.isFollowing = result.data.follow;
            }
          } else {
            //this.errorMessage = this.restError;
          }
        });
    },

    removeSharedId(userId) {
      this.sharedIds.splice(this.sharedIds.indexOf(userId), 1);
    },
    saveSharedIds() {
      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: this.saveIdsUrl + this.collectionId,
        contents: {
          shared_ids: this.sharedIds,
          message: this.sharedMessage,
        },
        acceptPromise: true
      }).then((result) => {
        if (!this.restError) {
          this.successMessage = this.$t('collectionDetail.collection_shared_successfully')
          this.showShareArea = false;
        } else {
          this.errorMessage = this.restError;
        }
      })
    },
    saveDetail() {
      let form = new FormData();
      form.append("name", this.item.name);
      form.append("description", this.item.description);
      form.append("is_public", this.item.is_public === true ? 1 : 0);
      form.append("_method", 'PUT');

      this.createFormDataForList(form, 'tags', this.hashtags);

      this.$store.dispatch(CREATE_ITEM, {
        url: this.CollectionDetailUrl + this.collectionId,
        contents: form,
        acceptPromise: true
      })
        .then((result) => {
          if (!this.restError) {
            this.item = result.data
            this.item.is_public = result.data.is_public !== '0';
          } else {
            this.errorMessage = this.restError;
          }
        });
    },
    addTag(newTag, id = null) {
      var regexp = /^[a-zA-Z0-9-_]+$/;
      if (newTag.search(regexp) == -1) {
        return false;
      }
      const tag = {
        name: newTag,
        id: id ?? 'custom_' + newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.hashtags_options.push(tag);
      this.hashtags.push(tag);
    },
    fixDate(value) {
      return moment(value).locale("tr").format("LL");
    },
    // handleNameInput: _.debounce(function () {
    //   let form = new FormData();
    //   form.append("name", this.item.name);
    //   form.append("_method", 'PUT');
    //   this.$store
    //       .dispatch(CREATE_ITEM, {
    //         url: this.CollectionDetailUrl + this.collectionId,
    //         contents: form,
    //         acceptPromise: true
    //       }).then((result) => {
    //     if (!this.restError) {
    //       this.item = result.data
    //     } else this.errorMessage = this.restError;
    //   })
    // }, 1500),
    handleCollectionToDelete() {
      if (confirm("Bu işlem geri alınamamaktadır. Devam etmek istediğinize emin misiniz?"))
        this.$store
          .dispatch(DELETE_ITEM_BY_ID, {
            url: this.CollectionDetailUrl + this.collectionId,
          })
          .then((result) => {
            if (!this.restError) {
              this.$router.push({ name: 'User.my-collection' });
            } else this.errorMessage = this.restError;
          });
    },
    getModelRouteName(model = '') {
      let model_name = model.split('\\').pop()
      switch (model_name) {
        case 'Assignment':
          return 'user.assignment-detail'
        case 'Training':
          return 'user.eduction-detail'
        default:
          return ''
      }
    },
    checkModelAble(model = '') {
      return model.split('\\').pop()
    },
    checkModelControl(content) {
      return content.pivot && this.checkModelAble(content.pivot.contentable_type) != 'CollectionExternalContent' && content.pivot.contentable_type != null;
    },
    removeContent(content_id) {
      //remove content from collection
      let form = new FormData();
      form.append("collection_content_id", content_id);
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: this.CollectionRemoveContentUrl + this.collectionId,
          contents: form,
          acceptPromise: true
        })
        .then((result) => {
          if (!this.restError) {
            this.getResultItems()
          } else this.errorMessage = this.restError;
        });
    },
    onChangeFile() {
      let value = this.$refs.file.files[0];
      this.collectionImage = value;
      let blob = URL.createObjectURL(value);
      let imageHolder = $("#collection-image-holder");
      imageHolder.attr("src", blob);

      let form = new FormData();
      form.append("cover_image", value);
      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: this.CollectionDetailUrl + this.collectionId + "/photo",
        contents: form,
      })
    },
    getResultItems() {
      this.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: this.CollectionDetailUrl + this.collectionId,
          acceptPromise: false,
        })
        .then((result) => {
          if (typeof result !== "undefined" && result.status) {
            this.item = result.data;
            this.item.is_public = result.data.is_public === 1;
            this.hashtags = JSON.parse(this.item.tags);

            if (typeof this.item.follows !== 'undefined') {
              this.isFollowing = (this.item.follows.length > 0) ? true : false;
            }
          }
        });
    },
    getUserList() {
      this.$store
        .dispatch(GET_ITEMS, {
          url: this.userListUrl + this.collectionId,
          acceptPromise: false
        })
        .then((result) => {
          if (typeof result !== "undefined" && result.status) {
            this.users = result.data.users;
            this.searchUserList = result.data.users;
            this.sharedIds = result.data.sharedIds;
          }
        });
    },

  },
  mounted() {
    this.collectionId = this.$route.params.id;
    this.getResultItems();
    this.getUserList();
  },
  beforeDestroy() {
    const modal = UIkit.modal("#open_to_add_content_modal");
    if (modal) modal.$destroy(true);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.uk-sticky-fixed {
  margin: 0 !important;
}

.multiselect .multiselect__input {
  border: unset !important;
  border: 0 !important;
  box-shadow: none;
  outline: none;
}
</style>
